import commonHelper from '@/app/utility/common.helper.utility';
import CrmMasterProjects from '../crmMasterProject';
import CrmSectorDetails from './crmSectorDetail';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'CrmSector',
  components: {
    CrmMasterProjects,
    CrmSectorDetails
  },
  props: ['crmMasterProjName', 'crmMasterProjId', 'crmSectorComp'],
  watch: {
    currentPage: function() {},
    perPage: function() {
      this.currentPage = 1;
    }
  },
  data() {
    return {
      unsubscribe: null,
      showCrmSectorModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      currentPage: 1,
      payload: {},
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      crmMasterPrj: {
        value: null,
        text: null
      },
      prjMasterPrj: {
        value: null,
        text: null
      },
      crmSectorCode: null,
      crmSectorName: null,
      tab: null,
      crmSectorRowData: null,
      crmSectorData: [],
      crmSectorFields: [
        {
          key: 'crm_master_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_master_project_name',
          class: 'd-none'
        },
        {
          key: 'crm_sector_code',
          label: 'Sector Code'
        },
        {
          key: 'crm_sector_id',
          class: 'd-none'
        },
        {
          key: 'crm_sector_name',
          label: 'Sector Name'
        },
        {
          key: 'master_project_name',
          class: 'd-none'
        },
        {
          key: 'master_project_id',
          class: 'd-none'
        },
        {
          key: 'sector_id',
          class: 'd-none'
        },
        {
          key: 'sector_name',
          label: 'PRJ Sector Name'
        }
      ]
    };
  },
  validations: {
    crmMasterPrj: {
      text: {
        required
      }
    }
  },
  mounted() {
    if (this.crmSectorComp) {
      this.crmMasterPrj.text = this.crmMasterProjName;
      this.getCrmSectorList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideCrmSectorModal(true, 'CrmSectorDetails');
          this.crmSectorRowData = null;
        }
        if (actionName === 'download' && !this.showCrmSectorModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'crmInventoryProperty/getCrmSectorList',
            'crm-sector',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CrmMasterProjects') {
        return (this.childName = 'CRM Master Projects');
      } else if (tab === 'CrmSectorDetails') {
        return (this.childName = 'CRM Sector Details');
      }
    },
    hideCrmSectorModal(flag, component) {
      this.showCrmSectorModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    getCrmSectorList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          crm_master_project_id: this.crmMasterProjId
            ? this.crmMasterProjId
            : this.crmMasterPrj.value,
          crm_sector_code: this.crmSectorCode,
          crm_sector_name: this.crmSectorName
        };
        this.loader = true;
        this.$store
          .dispatch('crmInventoryProperty/getCrmSectorList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.crmSectorData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    rowSelected(rowData) {
      this.crmSectorRowData = rowData;
      this.$emit('selectedCrmSector', this.crmSectorRowData);
      this.hideCrmSectorModal(true, 'CrmSectorDetails');
    },
    selectedCrmMasterProj(item) {
      this.showCrmSectorModal = false;
      this.crmMasterPrj = {
        value: item.crm_master_project_id,
        text: item.crm_master_project_name
      };
      this.prjMasterPrj.text = item.master_project_name;
    },
    updateCrmSectorList() {
      this.getCrmSectorList();
    },
    resetSearchFilters() {
      this.crmMasterPrj = {
        value: null,
        text: null
      };
      this.crmSectorCode = '';
      this.crmSectorName = '';
      this.prjMasterPrj.text = '';
      this.crmSectorData = [];
      this.totalRows = null;
      this.currentPage = 1;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
