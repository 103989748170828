import PrjSector from '../../../project/projectSector';
import CrmMasterProject from '../../crmMasterProject';
import PrjMasterProject from '../../../project/projectMaster';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'CrmSectorDetails',
  components: {
    PrjSector,
    CrmMasterProject,
    PrjMasterProject
  },
  props: {
    crmSectorRowData: Object
  },
  data() {
    return {
      unsubscribe: null,
      showCrmSectorModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      prjMasterPrj: {
        value: null,
        text: null
      },
      crmMasterPrj: {
        value: null,
        text: null
      },
      prjSector: {
        value: null,
        text: null
      },
      crmSectorCode: null,
      crmSectorName: null,
      crmSectorId: 0,
      tab: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmSector();
          this.showAlert = true;
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.crmSectorId });
        }
      }
    });
    if (this.crmSectorRowData !== null) {
      this.crmSectorCode = this.crmSectorRowData.crm_sector_code;
      this.crmSectorId = this.crmSectorRowData.crm_sector_id;
      this.crmSectorName = this.crmSectorRowData.crm_sector_name;
      this.prjSector = {
        value: this.crmSectorRowData.sector_id,
        text: this.crmSectorRowData.sector_name
      };
      this.crmMasterPrj = {
        value: this.crmSectorRowData.crm_master_project_id,
        text: this.crmSectorRowData.crm_master_project_name
      };
      this.prjMasterPrj = {
        value: this.crmSectorRowData.master_project_id,
        text: this.crmSectorRowData.master_project_name
      };
    } else {
      this.editMode = true;
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'PrjSector') {
        return (this.childName = 'PRJ Sector');
      } else if (tab === 'CrmMasterProject') {
        return (this.childName = 'CRM Master Project');
      } else if (tab === 'PrjMasterProject') {
        return (this.childName = 'PRJ Master Project');
      }
    },
    addEditCrmSector() {
      const payload = {
        crm_sector_code: this.crmSectorCode,
        crm_sector_id: this.crmSectorId,
        crm_sector_name: this.crmSectorName,
        crm_master_project_id: this.crmMasterPrj.value,
        project_sector_id: this.prjSector.value
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmSector', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateCrmSectorList');
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    hideCrmProjectMasterModal(flag, component) {
      if (component === 'CrmMasterProject' && !this.prjMasterPrj.text) {
        return alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
      }
      this.showCrmSectorModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    prjMasterProjectDetails(item) {
      this.showCrmSectorModal = false;
      this.prjMasterPrj = {
        value: item.master_project_id,
        text: item.master_project_name
      };
      this.crmMasterPrj = {
        value: null,
        text: null
      };
    },
    prjSectorDetails(item) {
      this.showCrmSectorModal = false;
      this.prjSector = {
        value: item.sector_id,
        text: item.sector_name
      };
    },
    selectedCrmMasterProj(item) {
      this.showCrmSectorModal = false;
      this.crmMasterPrj = {
        value: item.crm_master_project_id,
        text: item.crm_master_project_name
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
